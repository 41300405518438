import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { SiBluesky } from 'react-icons/si';

// Component for rendering social media links
const SocialLinks: React.FC = () => (
  <div className="social-links">
    <a href="https://github.com/adeadzeplin" target="_blank" rel="noopener noreferrer">
      <FaGithub size={24} />
    </a>
    <a href="https://www.linkedin.com/in/dansdp/" target="_blank" rel="noopener noreferrer">
      <FaLinkedin size={24} />
    </a>
    <a href="https://bsky.app/profile/adeadzeplin.bsky.social" target="_blank" rel="noopener noreferrer">
      <SiBluesky size={24} />
    </a>
  </div>
);

export default SocialLinks;
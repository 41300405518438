import React from 'react';

const ResumeSection: React.FC = () => {
  const skills = [
    "Spanish", "Python", "Bare Metal", "PIC", "Microcontrollers", "JTAG-debugger", "Oscilloscope", "Logic Analyzer",
    "C", "C++", "I2C", "ISR", "Hard RS232 Serial", "CSI2-MIPI", "Linux", "Jetson Nano", "Automated Tests",
    "Hardware Integration", "Hardware Validation", "Jira", "Atlassian", "C#", ".NET", "WPF", "QT", "UI/UX",
    "Cameralink", "Multithreading", "Git", "BugTracking", "ARM", "FreeRTOS", "Github", "OrCAD", "ECR/ECN",
    "Schematics", "React", "TypeScript", "JS", "HTML", "CSS", "SQL", "3D CAD Design", "OSAL", "ThreeJS",
    "React Three Fiber", "Unreal", "Blender", "HLSL", "API", "Orbital Mechanics", "Django", "Lua", "Steam API",
    "Sensors", "AutoCAD Inventor"
  ];

  const handleDownload = () => {
    // Replace 'path_to_your_resume.pdf' with the actual path to your resume file
    const resumeUrl = '/smithdepazdresume-full.pdf';
    
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = resumeUrl;
    link.download = 'Dan_Smith-dePaz_Resume.pdf'; // Set the desired file name
    document.body.appendChild(link);
    
    // Trigger the download
    link.click();
    
    // Clean up
    document.body.removeChild(link);
  };

  return (
    <div className="content-section resume-section">
      <h2><button className="download-resume-btn" onClick={handleDownload}>
        Download Resume
      </button> </h2>
      
      <div className="resume-content">
        <div className="resume-item">
          <h3>Skills</h3>
          <div className="skills-list">
            {skills.map((skill, index) => (
              <span key={index} className="skill-tag">{skill}</span>
            ))}
          </div>
        </div>
        
        <div className="resume-item">
          <h3>Professional Experience</h3>
          <h4>Lynred USA, Fairfield NJ - Software Engineer</h4>
          <p>MAR 2021 - FEB 2024</p>
          <ul>
            <li>Lead firmware design for a MIPI-compliant Infrared camera</li>
            <li>Responsible for feature implementations, system validation, and automated testing</li>
            <li>Convert loose requirements into actionable plans and execute them</li>
            <li>Implement ISR to handle I2C commands from a master device</li>
            <li>Debug hardware and software issues using an oscilloscope</li>
            <li>Document implemented features, user interfaces, and register map</li>
          </ul>
        </div>

        <div className="resume-item">
          <h4>MSA Safety, Cranberry PA - Software Engineering Coop</h4>
          <p>SEP 2019 - DEC 2019</p>
          <ul>
            <li>Work closely with domestic and international team members</li>
            <li>Design and implement a Menu UI on a microcontroller written in C, interfacing I/O with an OSAL</li>
            <li>Create development debug tools to improve developer quality of life</li>
            <li>Optimize packet transmissions to an LCD peripheral to increase performance by 50%</li>
          </ul>
        </div>

        <div className="resume-item">
          <h4>Hydroid Inc, Cape Cod MA - Electrical Engineering Coop</h4>
          <p>JAN 2019 - APR 2019</p>
          <ul>
            <li>Reduce labor costs by assisting in the modularization of product development</li>
            <li>Peer review schematics pending implementation</li>
            <li>Create new schematics based on ECR/ECNs</li>
          </ul>
        </div>

        <div className="resume-item">
          <h3>Education</h3>
          <h4>Wentworth Institute of Technology, Boston MA - BA Computer Engineering</h4>
          <p>SEP 2016 - AUG 2020</p>
          <p>Coursework: Operating Systems, Digital Signal Processing, Analog Circuit Design, Advanced Digital Circuit Design, Computer Architecture, Database Management Systems, Internet of Things</p>
        </div>
      </div>
    </div>
  );
};

export default ResumeSection;